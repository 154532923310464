export const GLOBAL_LOCATION_ID = 1;
export const PRINTER_TOOL_URL = 'https://storage.googleapis.com/bubblemania/PROD_ASSETS/PrinterTool.zip';
export const TIME_ZONE = 'Asia/Colombo';

export const COLOR_CODE = {
  red: '#FF4842',
  green: '#00AB55',
  yellow: '#FFC107',
};
export const CHECKER_STATUS_LIST = [
  { label: '🟠 Not Checked', color: '#ffe293', permissionCode: 'CHAT_USER_ADMIN' },
  { label: '✅ Checked', color: '#4CAF50', permissionCode: 'CHAT_USER_ADMIN' },
  { label: '❗ Attention Required', color: '#F44336', permissionCode: 'GENERAL_DATA' },
  { label: '🆗 Fixed', color: '#2196F3', permissionCode: 'GENERAL_DATA' },
];
export const SENDER_STATUS_LIST = [
  { label: '🟠 Pending', color: 'black' },
  { label: '✅ Purchased', color: '#4CAF50' },
  { label: '🔄 Buying or Return', color: '#2196F3' },
  { label: '❗ Complain', color: '#F44336' },
  { label: '🚫 Spam', color: '#B0BEC5' },
];

export const leaveStatus = [
  { id: 1, name: 'Pending', color: COLOR_CODE.yellow },
  { id: 2, name: 'Approved', color: COLOR_CODE.green },
  { id: 3, name: 'Declined', color: COLOR_CODE.red },
];
export const leaveTypes = [
  { id: 1, name: 'Annual Leave' },
  { id: 2, name: 'Sick Leave' },
  { id: 3, name: 'Maternity Leave' },
  { id: 4, name: 'Paternity Leave' },
  { id: 5, name: 'Unpaid Leave' },
  { id: 6, name: 'Other' },
];

export const dayLeaveType = [
  {
    id: 1,
    name: 'Full Day',
    startTime: 'dd/MM/yyyy 00:00:00',
    endTime: 'dd/MM/yyyy 23:59:59',
  },
  // {
  //   id: 2,
  //   name: 'First Half Day',
  //   startTime: 'dd/MM/yyyy 00:00:00',
  //   endTime: 'dd/MM/yyyy 12:00:00',
  // },
  // {
  //   id: 3,
  //   name: 'Second Half Day',
  //   startTime: 'dd/MM/yyyy 12:00:00',
  //   endTime: 'dd/MM/yyyy 23:59:59',
  // },
];
export const CASHFLOW_TYPES = [
  {
    id: 1,
    name: '(Debit) Deposit',
    code: 'DEPOSIT',
    isDebit: true,
    isShow: true,
  },
  {
    id: 2,
    name: '(Debit) POS Sales',
    code: 'DEPOSIT',
    isDebit: true,
    isShow: false,
  },
  {
    id: 3,
    name: '(Credit) Stock Supplier',
    code: 'STOCK_SUPPLIER',
    isDebit: false,
    isShow: true,
  },
  {
    id: 4,
    name: '(Credit) Asset Based Service',
    code: 'ASSET_BASED_SERVICE',
    isDebit: false,
    isShow: true,
  },
  {
    id: 5,
    name: '(Credit) Cash Drawer',
    code: 'OTHER_PAYMENTS',
    isDebit: false,
    isShow: false,
  },
  {
    id: 6,
    name: '(Credit) Other Payments',
    code: 'OTHER_PAYMENTS',
    isDebit: false,
    isShow: true,
  },
];
export const CASHFLOW_PAYMENT_STATUS = [
  {
    id: 1,
    name: 'Pending',
    code: 'PENDING',
    showInitially: false,
    color: 'red',
    textColor: 'white',
    visibleOnAdd: true,
    visibleOnEdit: false,
  },
  {
    id: 2,
    name: 'Schedule',
    code: 'SCHEDULED',
    showInitially: true,
    color: 'red',
    textColor: 'white',
    visibleOnAdd: true,
    visibleOnEdit: false,
  },
  {
    id: 3,
    name: 'Paid',
    code: 'PAID',
    showInitially: true,
    color: 'red',
    textColor: 'white',
    visibleOnAdd: true,
    visibleOnEdit: true,
  },
  {
    id: 4,
    name: 'Declined',
    code: 'DECLINED',
    showInitially: false,
    color: 'red',
    textColor: 'white',
    visibleOnAdd: false,
    visibleOnEdit: true,
  },
];

export const META_MESSAGES_UPDATE = {
  UPDATE_MESSAGES: 'UPDATE_MESSAGES',
  UPDATE_MESSAGES_TIME: 'UPDATE_MESSAGES_TIME',
  UPDATE_USERS: 'UPDATE_USERS',
  UPDATE_USERS_TIME: 'UPDATE_USERS_TIME',
};

export const CHAT_AVATAR_COLOR = '#0a0031';
